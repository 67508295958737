<template>
  <div>
    <div class="pititle">
      <el-form>
        <div class="litop">
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="项目名称">
                  <el-input
                    v-model="form.name"
                    placeholder="请输入您的项目名称"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="项目状态">
                  <el-select v-model="status" placeholder="项目状态">
                    <el-option label="待寄送" :value="12"></el-option>
                    <el-option label="待自取" :value="13"></el-option>
                  </el-select>
                </el-form-item>

              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="标识阶段">
                  <el-select v-model="form.stage" placeholder="标识阶段">
                    <el-option label="能效评测" :value="1"></el-option>
                    <el-option label="实测评估" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="标识星级">
                  <el-select v-model="form.after_stars" placeholder="标识星级">
                    <el-option label="一星" :value="1"></el-option>
                    <el-option label="二星" :value="2"></el-option>
                    <el-option label="三星" :value="3"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple"> <div class="prbtn">
                  <span style="margin-left:63px"></span>
                  <el-button type="primary">导出</el-button>
                </div></div>
            </el-col>
          </el-row>
        </div>
        <div class="litop">
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="建设单位">
                  <el-input
                    v-model="form.build_company"
                    placeholder="请输入建设单位"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="测评机构">
                  <el-input
                    v-model="form.test_company"
                    placeholder="请输入测评机构"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="批次编号">
                  <el-input
                    v-model="form.batch"
                    placeholder="请输入您的批次编号"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="建筑类型">
                  <el-select
                    v-model="form.building_type"
                    placeholder="建筑类型"
                  >
                    <el-option label="公共建筑" :value="1"></el-option>
                    <el-option label="居住建筑" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <div class="prbtn">
                  <span>共{{ totalCount }}条</span>
                  <el-button type="primary" @click="search">查询</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="piconetn">
        <!-- 表格 -->
        <div class="pitable">
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column label="序号" type="index">
            </el-table-column>
            <el-table-column
              label="批次编号"
              prop="batch"
              align="center"
            >
            </el-table-column>
            <el-table-column label="项目名称" prop="name" align="center" min-width="180"> </el-table-column>
            <el-table-column label="建筑类型" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.building_type == 1">公共建筑</span>
                <span v-if="row.building_type == 2">居住建筑</span>
              </template>
            </el-table-column>
            <el-table-column label="项目城市" prop="city" align="center">
            </el-table-column>
            <el-table-column label="建设单位" prop="build_company" align="center">
            </el-table-column>
            <el-table-column label="测评机构" prop="test_company" align="center">
            </el-table-column>
            <el-table-column label="标识星级" align="center">
              <template slot-scope="{ row }">
                <i
                  v-for="item in row.after_stars"
                  :key="item"
                  class="el-icon-star-on"
                ></i>
              </template>
            </el-table-column>
            <el-table-column label="标识阶段" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.stage == 1">能效评测</span>
                <span v-if="row.stage == 2">实测评估</span>
              </template>
            </el-table-column>
            <el-table-column label="申报时间" prop="created_at" align="center">
              <template slot-scope="{ row }">
                <span>{{
                  row.created_at | formatDate("yyyy-MM-dd")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="项目状态" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.status == 1">未提交</span>
                <span v-else-if="row.status == 2">形式初审</span>
                <span v-else-if="row.status == 3">形式复审</span>
                <span v-else-if="row.status == 4">审查分配</span>
                <span v-else-if="row.status == 5">专家初审</span>
                <span v-else-if="row.status == 6">专家复审</span>
                <span v-else-if="row.status == 7">专家反馈</span>
                <span v-else-if="row.status == 8">审核通过</span>
                <span v-else-if="row.status == 9">待公示</span>
                <span v-else-if="row.status == 10">公示中</span>
                <span v-else-if="row.status == 11">公告中</span>
                <span v-else-if="row.status == 12">待寄送</span>
                <span v-else-if="row.status == 13">待自取</span>
                <span v-else-if="row.status == 14">已完成</span>
                <span v-else-if="row.status == 15">形式审查待修改</span>
                <span v-else-if="row.status == 16">形式审查已修改</span>
                <span v-else-if="row.status == 17">技术审查待修改</span>
                <span v-else-if="row.status == 18">技术审查已修改</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <div class="pageTotal">
          <div class="block">
            <el-pagination
              :current-page="page"
              :page-count="totalPage"
              :page-size="limit"
              :total="totalCount"
              background
              layout=" prev, pager, next, total, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProjectList } from "../../../../api/project";

export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      status: '',
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //获得表格数据
    getList() {
      let that = this;

      let params = this.form;
      params.page = this.page;
      params.limit = this.limit;
      params.status = '2,3,4,5,6,7,8,9,10,22,12,13,14,15,16,17,18';
      params.token = this.$store.state.token;
      getProjectList(params)
        .then((res) => {
          // console.log(res);
          that.tableData = res.data;
          that.page = res.page;
          that.totalCount = res.totalCount;
          that.totalPage = res.totalPage;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //搜索
    search() {
      this.page = 1;
      this.getList();
    },

    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getList();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getList();
    },
  },
};
</script>
<style scoped>
/deep/ .el-table th.el-table__cell > .cell{
  min-width: 100px;
}
/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}
/deep/.el-form-item__label {
  font-size: 18px;
}
/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 255px;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #3086fb;
  font-weight: bold;
  font-size: 18px;
}
.pitable{
  /*height: 60vh;*/
}
.litop {
  margin: 20px 24px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 38px 0;
}
.prbtn {
  display: flex;
  flex-direction: row-reverse;
}
</style>
